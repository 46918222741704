.sc-jEACwC.isoBoxWrapper {
  border-radius: 0;
  background-color: transparent;
  border: none;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
.sc-ksZaOG.idjsZm.isoBoxWrapper{
  width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
padding: 0px ;
    /* margin: 0 0 30px; */
}

.isoBoxWrapper .ViewContainer button.active {
  border-color: #f80e46 !important;
}
._1nBOt {
  background-color: #F9F9FB !important;
  height: 63px !important;
}
._WuQ0f {
  padding: 10px 20px;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18px;
  color: #535763;
}
._2eZzQ {
  display: none;
}
._34SS0 {
  height: auto !important;
}
._3lLk3 {
  background-color: white !important;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  padding: 10px 20px;
  
  border-bottom: 1px solid #ebeff2;
  height: 64px;
}
._nI1Xw {
  color: #101828;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 20px;
}
._nI1Xw div:nth-child(1) {
  display: none;
}
._CZjuD > svg {
  height: 63px !important;
  border-bottom: #e6e4e4 1px solid;
  border-top: #e6e4e4 1px solid;
}

.calendar {
  height: 64px;
}
.gridBody .rows rect {
  height: 64px;
}
.calendar text {
  height: 64px;
  color: #535763;
  fill: #535763 !important;
}
._3_ygE {
  border-collapse: collapse;
}

.sc-jEACwC.isoBoxWrapper > div.sc-epALIP > div:nth-child(2) {
  background-color: #ffffff;
  border-radius: 15px;
  overflow: hidden;
}

@media only screen and (max-width: 1024px) {
  .cVKUjc > div:nth-child(2) ._3eULf {
    overflow-x: auto !important;
    background-color: white !important;
  }
  ._CZjuD {
    overflow: unset;
    background-color: white !;
  }
}

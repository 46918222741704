/* .dailyEventWrapper{
  color: black !important;
} */
.msgcust h1{
  padding:  15px 0px;
  color: gray;
  font-size: 1em;
  font-weight: 500;
 }
 .CustProgress{
  background-color: white;
    border: none;
    margin: 0px 20px 0px 20px;
    padding: 10px 0px 10px 10px;
    border-radius: 8px ;
   
}
.modeWrapper{

  text-align: start !important;
}
.yearlyMonth {
  float: inherit !important;
  background-color: white !important;
}
.awesome-calendar-header {
  background-color: #f0f0f0;
}
.calendarHeader h1{
  font-size: 1.5em;
}
.calendarHeader h1{
  margin-top: 10px;
  justify-content: center;
    display: flex;
  font-size: 1.5em;
}
.calendarHeaderButtons{
  margin: 10px 0px 10px 0px !important;
  }
 .modeButton {
  /* border-radius: 8px !important; */
  color: white !important;
   
    background-color: #535763 !important;
    border-radius: 8px !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-right: 10px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    border-color: #535763 !important;
}
.modeButtonActive{
  color: white !important;
  border-radius: 8px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-right: 10px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  background-color: rgb(248, 14, 70) !important;
  border-color: rgb(248, 14, 70) !important;;
}
.mainDiv {
  background-color: white;
  border: none;
  padding: 20px;
  border-radius: 8px;
  
}
.yearlyMonth {
  float: inherit !important;
  background-color: white !important;
}
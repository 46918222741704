.vistors-card-icon > .sidebar-icon > svg > path {
  stroke: #d20e00;
}
.assets-card-icon > .sidebar-icon > svg > path {
  stroke: #20b600;
}
.form-card-icon > .sidebar-icon > svg > path {
  stroke: #1877f2;
}

.wethermap-mp > .bowlBQ {
  height: 425px;
}

.ant-card-body .bowlBQ .map-container {
  border-radius: 20px;
}

.wethermap-map .wether-map-p > .ant-card-body .bowlBQ {
  height: 415px;
}

.wethermap-mp .ant-card-body,
.standard-property-map > .ant-card-body {
  padding: unset;
}

.manage-property-category {
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
  grid-auto-flow: column;
  gap: 20px;
}

.manage-property-category > div.wether-map-box {
  grid-row: span 3 / span 3;
}

.dashbord-btm-card-btn {
  background: #f9f9fb;
  border-radius: 8px;
  border: unset;
  width: 100%;
  height: 32px;
  font-size: 12px;
  font-weight: 700;
  margin-top: 20px;
}

.bottom-card span {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #171717;
  display: flex;
  justify-content: center;
  max-width: 112px;
}

.bottom-card {
  background: rgba(30, 31, 32, 0.05);
  border-radius: 24px;
  max-width: 263px;
  margin: 30px 0;
}

.bottom-card > .ant-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.row-span-2 {
  grid-row: span 2 / span 2;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.modal-title-style .ant-modal-header {
  background: #3f3f44;
}

.manage-property-header {
  padding: 20px 24px;
  display: flex;
  align-items: center;
  margin: auto;
}

.manage-property-header > .mp-title {
  width: 100%;
}

.manage-table .column-name span {
  display: none;
}
body #root .react-collapsible-theme .react-collapsible thead > tr > th {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #535763 !important;
  text-align: left;
  background-color: #f9f9fb;
 
}
body .manage-property-header .button-group .ant-select .ant-select-selector {
  border: 1px solid #d0d5dd !important;
  border-radius: 8px !important;
}
body
  .manage-property-header
  .button-group
  .ant-select.ant-select-arrow
  svg:fill {
  color: #e9e9e9;
}
body
  .manage-property-header
  .button-group
  .ant-select
  .ant-select-selector
  .ant-select-selection-item {
  color: #bcbcbc;
}
.manage-property-header .button-group .ant-btn {
  border-radius: 8px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.manage-property-header .button-group .ant-btn span {
  margin-right: 5px;
}

/*  */
.ant-modal-footer {
  display: flex;
}

#containerBox {
  padding: 0;
}

.manage-table .react-collapsible .name {
  padding: 24px 16px;
}
.manage-table{
  border-top: 1px solid #EAECF0;
}
.manage-table .react-collapsible tbody .name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101828;
}
.manage-table .react-collapsible tbody .address,
.manage-table .react-collapsible tbody .subscription_label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #475467;
}

@media only screen and (max-width: 76px) {
  .category-card .ant-col:nth-child(1) .bottom-card {
    margin-top: 30px;
  }
  .bottom-card {
    margin: 0 auto 30px auto;
  }
  .eXoanb .button-group {
    flex-direction: column-reverse;
  }
}
.manage-table table .expanded-row p .child-label {
  font-weight: 600;
}
.leaflet-top,
.leaflet-bottom {
  z-index: 999 ;
}

.user-access-modal  .ant-modal-content .ant-modal-body .ant-row:nth-child(2) .btn-summary-action .ant-btn{
  background-color: #f80e46;
  border-color: #f80e46;
}
/* media */
@media only screen and (max-width: 480px) {
  .manage-property-header {
    flex-direction: column;
    padding: 20px 15px;
  }
  .manage-property-header .button-group{
    justify-content:flex-start !important;
    margin: 10px 0 0 0 !important;
  }
}